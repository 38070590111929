import { httpClient } from './axiosClients'

export function createPackageJob (payload) {
  return httpClient.post('/api/esignatures/packages', payload)
}

export function pollPackageCreateJob (jobId) {
  return httpClient.get(`/api/esignatures/packages/jobs/${jobId}`)
}

export function refreshStatus (packageId) {
  return httpClient.post(`/api/esignatures/packages/${packageId}/refresh-status`)
}

export function getStatus (packageId) {
  return httpClient.get(`/api/esignatures/packages/${packageId}/status`)
}

export function getStakeholders (packageId) {
  return httpClient.get(`/api/esignatures/packages/${packageId}/stakeholders`)
}
