<!--

SignatureModal.vue

Displays a modal where the user can create a new eSignature session.

Authors:
 Michael Anckaert <michael.anckaert@sinax.be> (26-09-2018)
-->
<template>
  <Modal ref="modal" size="xl" title="Ondertekensessie starten">
    <div>
      <div class="tabs-container">
        <ul class="nav nav-tabs">
          <li class="active"><a data-toggle="tab" href="#tab-signing">Algemeen</a></li>
          <li class=""><a data-toggle="tab" href="#tab-actors">Betrokkenen</a></li>
          <li class=""><a aria-expanded="true" data-toggle="tab" href="#tab-documents">Documenten</a></li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane active" id="tab-signing">
            <div class="panel-body">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Naam</label>
                <div class="col-sm-4">
                  <input class="form-control" placeholder="Naam voor deze ondertekensessie" v-model="name">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Ondertekenen met</label>
                <div class="col-sm-4">
                  <div>
                    <label>
                      <input id="signType1" name="signType" type="radio" v-model="signType" value="eid">
                      eID / Itsme
                    </label>
                  </div>
                  <div>
                    <label>
                      <input id="signType2" name="signType" type="radio" v-model="signType" value="sms">
                      SMS OTP
                    </label>
                  </div>
                  <div>
                    <label>
                      <input id="signType3" name="signType" type="radio" v-model="signType" value="email">
                      E-mail OTP
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="tab-actors">
            <div class="panel-body">
              <h4>Snelle selectie</h4>
              <div v-for="(preset, index) in presets" :key="`preset_${index}`">
                <a @click="addStakeholder(preset.relation, preset.role)" class="btn btn-sm btn-default"
                   style="float: left; margin-right: 10px">
                  <i class="glyphicon glyphicon-plus-sign"></i>
                  {{ preset.role }}: {{ preset.relation.display_name }}
                </a>
              </div>
              <div style="clear: both; margin-bottom: 30px"></div>

              <h4>
                Geselecteerde betrokkenen
                <a
                  v-if="canChangeOrCreateStakeholder"
                  class="btn btn-default btn-xs"
                  @click="addNewStakeholder"
                >
                  <i class="glyphicon glyphicon-plus-sign" />
                </a>
              </h4>
              <div v-for="stakeholder in stakeholders" :key="`preset_${stakeholder.id}`" class="row tw-mt-4">
                <div class="col-sm-1" style="padding-top: 7px">
                  <em>{{ stakeholder.id }}</em>
                </div>
                <div class="col-sm-4">
                  <ContactPicker
                    v-model="stakeholder.relation"
                    :can-clear="canChangeOrCreateStakeholder"
                  />
                </div>
                <div class="col-sm-2">
                  <select class="form-control" v-model="stakeholder.type">
                    <option value="SIGNER">Ondertekenaar</option>
                    <option value="RECEIVER">Ontvanger</option>
                  </select>
                </div>
                <div class="col-sm-3">
                  <select class="form-control" v-model="stakeholder.role">
                    <option value="Verkoper">Verkoper</option>
                    <option value="Koper">Koper</option>
                    <option value="Kandidaat koper">Kandidaat-koper</option>
                    <option value="Verhuurder">Verhuurder</option>
                    <option value="Borgsteller">Borgsteller</option>
                    <option value="Makelaar">Makelaar</option>
                    <option value="Huurder">Huurder</option>
                    <option value="Partij tekent voor akkoord en kennisname">Partij tekent voor akkoord en kennisname</option>
                    <option value="Zich sterk makend voor">Zich sterk makend voor</option>
                    <option value="Volmachthouder">Volmachthouder</option>
                    <option value="Vertegenwoordiger van">Vertegenwoordiger van</option>
                    <option value="Bestuurder van">Bestuurder van</option>
                    <option value="Rentmeester">Rentmeester</option>
                    <option value="Tussenkomende partij">Tussenkomende partij</option>
                  </select>
                  <input
                    type="text"
                    v-model="stakeholder.addition"
                    placeholder="Toevoeging in geval van rol 'Volmachthouder', 'Vertegenwoordiger van' ..."
                    class="form-control tw-mt-2"
                  />
                </div>
                <div class="col-sm-2" style="padding-top: 7px">
                  <a class="btn btn-xs btn-danger" @click="removeStakeholder(stakeholder.id)">
                    <i class="glyphicon glyphicon-trash" />
                  </a>
                  <router-link
                    v-if="stakeholder.relation"
                    :to="{ name: 'ContactDetails', params: { id: stakeholder.relation.id } }"
                    target="_blank"
                    class="btn btn-xs btn-default"
                  >
                    <i class="glyphicon glyphicon-search" />
                  </router-link>
                  <i
                    v-if="validStakeholder(stakeholder).length"
                    :title="validStakeholder(stakeholder)"
                    class="glyphicon glyphicon-alert"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="tab-documents">
            <div class="panel-body">
              <p>Onderstaande documenten zullen worden opgenomen in de ondertekensessie.</p>
              <p>je kan documenten aanduiden om <strong>samen te voegen</strong>, deze worden in één keer ondertekend.
              </p>
              <table class="table table-hover">
                <thead>
                <tr>
                  <th>Document</th>
                  <th>Samenvoegen/handtekenveld toevoegen</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="document in documents" :key="`document_${document.id}`">
                  <td>
                    <span class="tw-font-bold">{{ document.title }}</span>
                    <br />
                    <span class="tw-text-xs">{{ document.filename }}</span>
                  </td>
                  <td><input type="checkbox" v-model="document.merge" /></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <button class="btn btn-link" data-dismiss="modal" type="button">Annuleren</button>
      <button @click="createPackage" class="btn btn-primary" type="button" :disabled="validateStakeholders().length > 0">Aanmaken</button>
      <a class="btn btn-info" target="_blank" href="https://docs.google.com/presentation/d/1OY9kpbN16HVLMhqtqbj2I_p3F_9zj2ACCsRtQtTkRwA">
        <i class="fa fa-question"></i>
        Help
      </a>
    </div>
  </Modal>
</template>

<script>
import swal from 'sweetalert2'
import { mapActions } from 'vuex'

import Modal from './iam/Modal'
import ContactPicker from './contacts/ContactPicker'

import { poll } from '@/utils/helpers'
import { startLoadingModal, errorModal } from '@/modalMessages'
import { getPropertyOwners, getPropertyBuyers, getPropertyRenters } from '@/services/properties'
import { createPackageJob, pollPackageCreateJob, refreshStatus } from '@/services/esignatures'

function zeroFill (number, width) {
  width -= number.toString().length
  if (width > 0) {
    return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number
  }
  return number + '' // always return a string
}

export default {
  name: 'SignatureModal',
  components: {
    ContactPicker,
    Modal
  },
  props: {
    property: {
      type: Object,
      required: true
    },
    loadData: {
      // For cases where we don't want to fetch the data and just pass the options ourselves
      type: Boolean,
      default: true
    },
    canChangeOrCreateStakeholder: {
      // To prevent the stakeholder selection being cleared and changed by the contact picker
      type: Boolean,
      default: true
    },
    documentType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      name: '',
      signType: 'eid',
      idCount: 0,
      stakeholders: [],
      documents: [],
      owners: [],
      renters: [],
      buyers: []
    }
  },
  computed: {
    presets () {
      const l = []
      this.owners.forEach((owner) => l.push({ role: 'Verkoper', relation: owner }))
      this.buyers.forEach((buyer) => l.push({ role: 'Koper', relation: buyer }))
      this.renters.forEach((renter) => l.push({ role: 'Huurder', relation: renter }))
      return l
    }
  },
  methods: {
    ...mapActions('properties', ['loadPropertySignaturePackages']),

    async show () {
      const promises = this.loadData
        ? await Promise.all([
          this.loadPropertyOwners(),
          this.loadPropertyRenters(),
          this.loadPropertyBuyers()
        ])
        : []
      this.$refs.modal.show()
      return promises
    },
    hide () {
      this.$refs.modal.hide()
    },

    async loadPropertyOwners () {
      try {
        const response = await getPropertyOwners(this.property.id)
        this.owners = response.data
        return response
      } catch {
        errorModal('Eigenaars kunnen niet worden geladen.')
      }
    },

    async loadPropertyBuyers () {
      try {
        const response = await getPropertyBuyers(this.property.id)
        this.buyers = response.data
        return response
      } catch {
        errorModal('Kopers kunnen niet worden geladen')
      }
    },

    async loadPropertyRenters () {
      try {
        const response = await getPropertyRenters(this.property.id)
        this.renters = response.data
        return response
      } catch {
        errorModal('Huurders kunnen niet worden geladen')
      }
    },

    validStakeholder (stakeholder) {
      const relation = stakeholder.relation
      const errors = []

      if (!relation) {
        return errors
      }

      if (relation.mobile === '') {
        errors.push('Geen GSM nr')
      }

      if (relation.email === '') {
        errors.push('Geen e-mail adres')
      }

      if (relation.first_name === '') {
        errors.push('Geen voornaam')
      }

      if (relation.last_name === '') {
        errors.push('Geen Naam')
      }

      if (stakeholder.role === '' && stakeholder.type === 'SIGNER') {
        errors.push('Geen rol gekozen')
      }

      return errors
    },
    validateStakeholders () {
      const errors = []
      if (!this.stakeholders.length) return ['Geen stakeholders geselecteerd']
      this.stakeholders.forEach((stakeholder) => {
        const e = this.validStakeholder(stakeholder)
        if (e) {
          errors.push(...e)
        }
      })
      return errors
    },
    addStakeholder (relation, role) {
      this.stakeholders.push({
        id: this.nextId(),
        relation: relation,
        type: 'SIGNER',
        role: role || ''
      })
    },
    addNewStakeholder () {
      this.stakeholders.push({
        id: this.nextId(),
        relation: null,
        type: 'SIGNER',
        role: ''
      })
    },
    removeStakeholder (stakeholderId) {
      const idx = this.stakeholders.findIndex((s) => s.id === stakeholderId)
      if (idx > -1) {
        this.stakeholders.splice(idx, 1)
        this.idCount -= 1
        let count = 1
        this.stakeholders.forEach((stakeholder) => {
          stakeholder.id = 'SIG' + zeroFill(count, 2)
          count += 1
        })
      }
    },
    nextId () {
      this.idCount += 1
      return 'SIG' + zeroFill(this.idCount, 2)
    },

    pollPackageUrl (package_id) {
      return new Promise((resolve, reject) => {
        const timer = setInterval(async () => {
          try {
            const response = await refreshStatus(package_id)
            const { status, f2f_url } = await response?.data?.data
            if (status === 'Pending') {
            // If the package is pending we show the succesfully created status
              clearInterval(timer)
              const result = await swal({
                title: 'Ondertekensessie is aangemaakt',
                text: 'De ondertekensessie is succesvol aangemaakt. Er is een e-mail uitnodiging gestuurd naar alle ondertekenaars.',
                type: 'success',
                showCancelButton: true,
                confirmButtonColor: '#21d66a',
                cancelButtonColor: '#166cdd',
                confirmButtonText: 'Start live tekensessie',
                cancelButtonText: 'Sluiten'
              })

              if (result.value) {
              // Open popup to start live session.
                const win = window.open(f2f_url, '_blank')
                if (!win) errorModal('Sta pop-ups toe om de ondertekensessie te starten.')
                else this.$emit('live-session-started')
              }
            } else {
            // Show the waiting modal, allowing the user to cancel the wait and shutdown the timer
              const result = await swal({
                title: 'Je ondertekenpakket wordt aangemaakt',
                text: 'IAM is bezig je ondertekenpakket aan te maken, dit kan enkele minuten duren. Als je wil kan je dit venster nu sluiten of even blijven wachten. We laten je iets weten als het pakket volledig is aangemaakt.'
              })
              if (result.value) clearInterval(timer)
              resolve(response)
            }
          } catch (error) {
            reject(error)
          }
        }, 10000)
      })
    },
    async createPackage () {
      try {
        startLoadingModal('De ondertekensessie wordt aangemaakt...')

        const data = {
          stakeholders: [],
          documents: [],
          name: this.name,
          type: this.signType,
          property: this.property.id,
          document_type: this.documentType
        }

        this.stakeholders.forEach((stakeholder) => {
          data.stakeholders.push({
            sig: stakeholder.id,
            relation: stakeholder.relation.id,
            type: stakeholder.type,
            role: stakeholder.role,
            addition: stakeholder.addition
          })
        })

        this.documents.forEach((document) => {
          data.documents.push({
            document: document.id,
            merge: document.merge || false
          })
        })

        const response = await createPackageJob(data)
        const { job_id, success } = response.data
        if (!success) throw new Error('Failed to generate package')

        this.name = ''
        this.signType = 'eid'
        this.idCount = 0
        this.stakeholders = []
        this.documents = []
        this.loadPropertySignaturePackages(this.property.id)
        this.hide()

        const packagePollResponse = await poll(job_id, pollPackageCreateJob, 3000)
        this.$emit('package-id-generated', packagePollResponse.package)
        return this.pollPackageUrl(packagePollResponse.package)
      } catch (error) {
        console.error(error)
        const errorMessage = JSON.stringify(error.response?.data?.error_data) || error.response?.data?.message || ''
        errorModal('De ondertekensessie kon niet worden aangemaakt. ' + errorMessage)
      }
    }
  }
}
</script>
